body {
  padding: 0;
  margin: 0; }

[data-mol-fe-rotator] {
  overflow: hidden;
  position: relative;
  display: block;
  padding: 2px;
  font-family: Arial,Helvetica,sans-serif; }

[data-mol-fe-rotator], [data-mol-fe-rotator] * {
  box-sizing: border-box; }

[data-mol-fe-rotator] li, [data-mol-fe-rotator] ul {
  list-style: none; }

[data-mol-fe-rotator] a, [data-mol-fe-rotator] a:active, [data-mol-fe-rotator] a:focus, [data-mol-fe-rotator] a:hover {
  text-decoration: none; }

[data-mol-fe-rotator] .mol-fe-rotator-header {
  font-size: 16px;
  margin: 0 0 1px;
  padding: 0 10px;
  height: 50px;
  font-weight: 700;
  overflow: hidden;
  border-style: solid;
  border-width: 1px; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body {
  overflow: hidden;
  height: 100%;
  display: table; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body > span {
  display: table-cell;
  vertical-align: middle; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .logo > a {
  display: block;
  padding-right: 10px;
  border-right-style: solid;
  border-right-width: 1px; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .logo svg {
  width: 111px;
  height: 18px; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .more-link {
  white-space: nowrap; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .title {
  width: 100%;
  text-transform: uppercase;
  padding: 0 2px 0 10px;
  padding-right: 10px; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .title span {
  white-space: nowrap; }

[data-mol-fe-rotator] .mol-fe-rotator-body {
  position: relative;
  overflow: hidden;
  height: 298px; }

[data-mol-fe-rotator] .mol-fe-rotator-body .items-holder {
  height: 100%;
  margin: 0 -1px;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box; }

[data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 163px;
  height: 50%;
  float: left;
  padding: 1px;
  overflow: hidden; }

[data-mol-fe-rotator] .mol-fe-rotator-item a {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden; }

[data-mol-fe-rotator] .mol-fe-rotator-item a img {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0; }

[data-mol-fe-rotator] .mol-fe-rotator-item a .description-mask, [data-mol-fe-rotator] .mol-fe-rotator-item a .description-wrapper, [data-mol-fe-rotator] .mol-fe-rotator-item a .thumb {
  position: absolute;
  left: 0;
  width: 100%; }

[data-mol-fe-rotator] .mol-fe-rotator-item a .thumb {
  height: 100px;
  overflow: hidden;
  top: 0;
  background-size: cover;
  background-position: top; }

[data-mol-fe-rotator] .mol-fe-rotator-item a .description-mask {
  bottom: 0;
  height: 6px; }

[data-mol-fe-rotator] .mol-fe-rotator-item a .description-wrapper {
  height: 64px;
  padding: 6px;
  padding-bottom: 0;
  border-style: solid;
  border-width: 0 1px 1px;
  bottom: 0; }

[data-mol-fe-rotator] .mol-fe-rotator-item a .description-wrapper .description {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden; }

[data-mol-fe-rotator].desktop .mol-fe-rotator-item a .description-wrapper {
  transform: translateY(17px);
  transition: transform .35s,background-color .35s,border-color .35s; }

[data-mol-fe-rotator].desktop .mol-fe-rotator-item a .description-mask {
  transition: background-color .35s,border-color .35s; }

[data-mol-fe-rotator].desktop .mol-fe-rotator-item a:hover .description-wrapper {
  transform: translateY(0); }

[data-mol-fe-rotator] .channel-header {
  color: #fff;
  background: var(--channel-color); }

[data-mol-fe-rotator] .channel-header .svg-circle-main circle, [data-mol-fe-rotator] .channel-header .svg-path-main path, [data-mol-fe-rotator] .channel-header .svg-polygon-main polygon {
  fill: var(--channel-color); }

[data-mol-fe-rotator] .channel-header .svg-circle circle, [data-mol-fe-rotator] .channel-header .svg-path path, [data-mol-fe-rotator] .channel-header .svg-polygon polygon {
  fill: #fff; }

[data-mol-fe-rotator] .channel-header a, [data-mol-fe-rotator] .channel-header a:focus, [data-mol-fe-rotator] .channel-header a:hover {
  color: #fff; }

[data-mol-fe-rotator] .channel-link:focus.colours-target, [data-mol-fe-rotator] .channel-link:focus .colours-target, [data-mol-fe-rotator] .channel-link:hover.colours-target, [data-mol-fe-rotator] .channel-link:hover .colours-target {
  background-color: var(--channel-color);
  border-color: var(--channel-color);
  color: #fff; }

[data-mol-fe-rotator].banner-board-on .banner-board {
  display: block; }

[data-mol-fe-rotator] .banner-board {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: 400;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: #000;
  background-image: url(../assets/7c41f196e3eeb62ccc9555d7c3f12fad.jpg);
  background-size: cover;
  font-size: 30px;
  text-decoration: none;
  color: #fff; }

[data-mol-fe-rotator] .banner-board .mol-logo {
  display: inline-block;
  width: 166px;
  height: 27px; }

[data-mol-fe-rotator] .banner-board .mol-logo svg {
  width: 100%;
  height: 100%; }

[data-mol-fe-rotator] .banner-board .mol-logo svg path, [data-mol-fe-rotator] .banner-board .mol-logo svg polygon {
  fill: #fff; }

[data-mol-fe-rotator] .banner-board .banner-board-body-wrapper {
  display: table;
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 50px; }

[data-mol-fe-rotator] .banner-board .banner-board-body-wrapper .banner-board-body {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding: 10px; }

[data-mol-fe-rotator] .banner-board .banner-board-body-wrapper .banner-board-body .play-icon {
  display: block;
  width: 58px;
  height: 58px; }

[data-mol-fe-rotator] .banner-board .banner-board-body-wrapper .banner-board-body .play-icon svg {
  width: 100%;
  height: 100%; }

[data-mol-fe-rotator].paginator-on .items-holder {
  transition: margin-left 1s; }

[data-mol-fe-rotator].paginator-on .hide {
  display: none !important; }

[data-mol-fe-rotator].paginator-on .paginator-next-page {
  display: inline-block; }

[data-mol-fe-rotator] .paginator-page-counter, [data-mol-fe-rotator]:not(.paginator-on) .paginator-next-page, [data-mol-fe-rotator]:not(.paginator-on) .paginator-page-counter {
  display: none; }

[data-mol-fe-rotator] .paginator-page-counter {
  vertical-align: middle;
  white-space: nowrap;
  pointer-events: none;
  text-align: center;
  font-size: 16px;
  font-weight: 400; }

[data-mol-fe-rotator] .mol-fe-rotator-body .paginator-next-page {
  padding: 1px 0;
  position: absolute;
  width: 34px;
  height: 100%;
  top: 0;
  right: 0; }

[data-mol-fe-rotator] .mol-fe-rotator-body .paginator-next-page .paginator-next-page-body {
  display: block;
  width: 100%;
  height: 100%; }

[data-mol-fe-rotator] .paginator-next-page .paginator-next-page-body {
  background-color: rgba(255, 255, 255, 0.7); }

[data-mol-fe-rotator] .paginator-next-page .paginator-next-page-body:before {
  transition: transform .3s ease;
  content: "";
  display: block;
  margin: -16px -10px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 33px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMzIiB2aWV3Qm94PSIwIDAgMjAgMzMiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTkuNjkgMTUuNzg5LTEuNTAyLTEuNDIyaC0uMDAxbC0xNC44NzMtMTQuMDc0Yy0uNDEzLS4zOTEtMS4wODktLjM5MS0xLjUwMiAwbC0xLjUwMyAxLjQyMmMtLjQxMy4zOTEtLjQxMyAxLjAzMSAwIDEuNDIxbDE0LjEyMiAxMy4zNjMtMTQuMTIyIDEzLjM2M2MtLjQxMy4zOTEtLjQxMyAxLjAzMSAwIDEuNDIybDEuNTAzIDEuNDIyYy40MTMuMzkxIDEuMDg5LjM5MSAxLjUwMiAwbDE0Ljg3My0xNC4wNzRoLjAwMWwxLjUwMi0xLjQyMmMuNDEzLS4zOTEuNDEzLTEuMDMxIDAtMS40MjEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: cover; }

[data-mol-fe-rotator] .paginator-next-page .paginator-next-page-body.reverse:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMzIiB2aWV3Qm94PSIwIDAgMjAgMzMiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTkuNjkgMTUuNzg5LTEuNTAyLTEuNDIyaC0uMDAxbC0xNC44NzMtMTQuMDc0Yy0uNDEzLS4zOTEtMS4wODktLjM5MS0xLjUwMiAwbC0xLjUwMyAxLjQyMmMtLjQxMy4zOTEtLjQxMyAxLjAzMSAwIDEuNDIxbDE0LjEyMiAxMy4zNjMtMTQuMTIyIDEzLjM2M2MtLjQxMy4zOTEtLjQxMyAxLjAzMSAwIDEuNDIybDEuNTAzIDEuNDIyYy40MTMuMzkxIDEuMDg5LjM5MSAxLjUwMiAwbDE0Ljg3My0xNC4wNzRoLjAwMWwxLjUwMi0xLjQyMmMuNDEzLS4zOTEuNDEzLTEuMDMxIDAtMS40MjEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIDEgMjAgMCkiLz48L3N2Zz4=); }

.mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-body, [data-mol-fe-rotator].mol-fe-rotator-fixed .mol-fe-rotator-body {
  width: 100%; }

@media screen and (max-width: 519px) {
  .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-header .more-link, .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-header .title, [data-mol-fe-rotator].mol-fe-rotator-fixed .mol-fe-rotator-header .more-link, [data-mol-fe-rotator].mol-fe-rotator-fixed .mol-fe-rotator-header .title {
    font-size: 14px; } }

@media screen and (max-width: 374px) {
  .mol-desktop [data-mol-fe-rotator] .more-link, [data-mol-fe-rotator].mol-fe-rotator-fixed .more-link {
    font-size: 12px; }
  .mol-desktop [data-mol-fe-rotator] .more-link a > span, [data-mol-fe-rotator].mol-fe-rotator-fixed .more-link a > span {
    display: none; }
  .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-header .logo svg, [data-mol-fe-rotator].mol-fe-rotator-fixed .mol-fe-rotator-header .logo svg {
    width: 90px;
    height: 15px; } }

.mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 33.33333%;
  display: block;
  padding-right: 1px;
  padding-left: 1px; }

.mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(3n) {
  padding-right: 0; }

.mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(3n+1) {
  padding-left: 0; }

.mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(n+7) {
  display: none; }

.alpha .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 25%;
  display: block;
  padding-right: 1px;
  padding-left: 1px; }

.alpha .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(4n) {
  padding-right: 0; }

.alpha .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(4n+1) {
  padding-left: 0; }

.alpha .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(n+9) {
  display: none; }

.gamma .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 20%;
  display: block;
  padding-right: 1px;
  padding-left: 1px; }

.gamma .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(5n) {
  padding-right: 0; }

.gamma .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(5n+1) {
  padding-left: 0; }

.gamma .mol-desktop [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(n+11) {
  display: none; }

.mol-desktop .alpha [data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 25%;
  display: block;
  padding-right: 1px;
  padding-left: 1px; }

.mol-desktop .alpha [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(4n) {
  padding-right: 0; }

.mol-desktop .alpha [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(4n+1) {
  padding-left: 0; }

.mol-desktop .alpha [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(n+9) {
  display: none; }

.mol-desktop .gamma [data-mol-fe-rotator] .mol-fe-rotator-item {
  width: 20%;
  display: block;
  padding-right: 1px;
  padding-left: 1px; }

.mol-desktop .gamma [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(5n) {
  padding-right: 0; }

.mol-desktop .gamma [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(5n+1) {
  padding-left: 0; }

.mol-desktop .gamma [data-mol-fe-rotator] .mol-fe-rotator-item:nth-child(n+11) {
  display: none; }

.mol-mobile [data-mol-fe-rotator] .items-holder, [data-mol-fe-rotator].mol-fe-rotator-paginated .items-holder {
  width: 10000px; }

.mol-mobile [data-mol-fe-rotator] .items-holder .mol-fe-rotator-item, [data-mol-fe-rotator].mol-fe-rotator-paginated .items-holder .mol-fe-rotator-item {
  width: 163px !important; }

@media screen and (max-width: 349px) {
  .mol-mobile [data-mol-fe-rotator] .mol-fe-rotator-header .paginator-page-counter, .mol-mobile [data-mol-fe-rotator] .mol-fe-rotator-header .title, [data-mol-fe-rotator].mol-fe-rotator-paginated .mol-fe-rotator-header .paginator-page-counter, [data-mol-fe-rotator].mol-fe-rotator-paginated .mol-fe-rotator-header .title {
    font-size: 12px; }
  .mol-mobile [data-mol-fe-rotator] .mol-fe-rotator-header .logo svg, [data-mol-fe-rotator].mol-fe-rotator-paginated .mol-fe-rotator-header .logo svg {
    width: 90px; } }

.mol-mobile [data-mol-fe-rotator] .items-holder .mol-fe-rotator-item:nth-child(6n+1) {
  clear: left; }

[data-mol-fe-rotator] {
  height: 338px; }

[data-mol-fe-rotator] .mol-fe-rotator-header {
  height: 36px !important;
  border-width: 0 !important; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .logo {
  display: none; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .title {
  padding-left: 0 !important; }

[data-mol-fe-rotator] .mol-fe-rotator-header .mol-fe-rotator-header-body .more-link {
  display: none !important; }

[data-mol-fe-rotator] .items-holder .mol-fe-rotator-item a {
  color: #fff;
  background: #000; }

[data-mol-fe-rotator] .items-holder .mol-fe-rotator-item a .description-wrapper {
  background: #000;
  border-width: 0 !important; }

[data-mol-fe-rotator] .items-holder .mol-fe-rotator-item a .description-mask {
  background: #000; }

[data-mol-fe-rotator] .embed-button, [data-mol-fe-rotator] .embed-modal {
  display: none; }

[data-mol-fe-rotator].embed-manager-on .embed-button {
  display: table-cell;
  opacity: .5;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;
  vertical-align: middle; }

[data-mol-fe-rotator].embed-manager-on .embed-button > span {
  display: block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  overflow: hidden; }

[data-mol-fe-rotator].embed-manager-on .embed-button > span span {
  float: right; }

[data-mol-fe-rotator].embed-manager-on .embed-button > span span svg {
  height: 24px;
  width: 24px; }

[data-mol-fe-rotator].embed-manager-on .embed-button:hover {
  display: table-cell;
  opacity: 1; }

[data-mol-fe-rotator].embed-manager-on .embed-modal {
  text-transform: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  color: #000;
  padding: 0 15px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal.show {
  display: table; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .table-cell {
  display: table-cell;
  vertical-align: middle; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-button {
  display: inline-block;
  width: 112px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  line-height: 42px;
  position: relative;
  user-select: none;
  margin-left: 7px;
  margin-right: 7px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-button.button-copy {
  background-color: #0049b6;
  color: #fff; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-button.button-copy:active {
  background-color: #003f9d; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-button.button-cancel {
  background-color: #fff;
  border: 1px solid silver;
  color: #0049b6; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-button.button-cancel:active {
  background-color: #f2f2f2; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-content {
  padding: 45px;
  position: relative;
  border: 10px solid #e3e3e3;
  width: 100%;
  max-width: 636px;
  background: #fff;
  margin: 0 auto; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-header {
  height: 58px;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em;
  border-bottom: 2px solid #e3e3e3; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-header .embed-icon {
  float: left;
  margin-right: 10px;
  width: 36px;
  height: 36px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-header .embed-icon svg {
  width: 100%;
  height: 100%; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-header .embed-icon svg circle {
  fill: #00a9d4; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-header .embed-icon svg path {
  fill: #fff; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-body {
  background-color: #f3f3f5;
  border: 1px solid #00a9d4;
  margin: 0 auto;
  margin-bottom: 11px;
  padding: 9px;
  line-height: 2em;
  font-weight: 400;
  font-size: 13px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-footer {
  text-align: center; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-copy-message {
  position: absolute;
  top: 0;
  left: 131px;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  color: #0cac0c;
  font-weight: 700;
  line-height: 24px;
  margin-top: 10px;
  white-space: nowrap; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-copy-message svg {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 7px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-copy-message svg path {
  fill: #0cac0c; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-copy-message.warning {
  color: #00a9d4; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-copy-message.show {
  opacity: 1; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-close {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  top: -24px;
  right: -24px;
  cursor: pointer;
  font-weight: 700; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-close svg {
  height: 100%; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-close svg path {
  fill: #fff; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-legal {
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  margin-bottom: 20px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-legal a, [data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-legal a:active, [data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-legal a:focus, [data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-legal a:hover {
  text-decoration: none;
  color: #0049b6; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme {
  margin-top: 9px;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 28px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-header {
  height: 32px;
  line-height: 32px;
  margin-bottom: 10px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-body {
  display: table;
  width: 100%;
  height: 170px;
  font-size: 14px;
  line-height: 14px;
  color: #000;
  border-bottom: 2px solid #e3e3e3;
  padding-bottom: 16px; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-body .section {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-body .section .icon {
  width: 215px;
  height: 131px;
  padding: 9px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  cursor: pointer; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-body .section .icon.active {
  padding: 5px;
  border: 4px solid #00a9d4; }

[data-mol-fe-rotator].embed-manager-on .embed-modal .embed-modal-choose-theme .embed-modal-choose-theme-body .section .icon svg {
  width: 100%;
  height: 100%; }

/*# sourceMappingURL=main.css.map*/
